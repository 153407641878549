import * as React from "react"
import Styled from 'styled-components/macro';

import Meta from './meta'
import Layout from './layout'
import Content from './content'
import Container from './container'

const Screen = Styled.div`
    margin: 120px 0;
    background-color: ${(props) => props.theme.colors.primary};
    min-height: 33rem;
`;

const NavButtonLink = Styled.a`
    cursor: pointer;
`;

const Options = Styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (max-width: 900px) {
        display: block;
    }
`;

const ButtonCard = Styled.div`
    display: block;
    align-items: center;
    justify-content: center;
    font-family: ${(props) => props.theme.fonts.regular};
    background-color: ${(props) => props.theme.colors.black};
    border: 1px solid transparent;
    color: ${(props) => props.theme.colors.primary};
    padding: 2rem 3rem;
    cursor: pointer;
    text-align: center;
    border-radius: 10px;
    max-width: 350px;
    width: ${(props) => (props.gridify ? '350px' : 'auto')};
    margin: 0 2rem 2rem 0;

    &:hover {
        background-color: ${(props) => props.theme.colors.secondary};
        color: ${(props) => props.theme.colors.black};
    }

    @media (max-width: 900px) {
        width: 100%;
        max-width: 100%;
        margin: 0 0 2rem 0;
    }
`;

const Label = Styled.h6`
    font-size: 2.4rem;
    font-weight: 600;
    margin: 0;
`;

const Caption = Styled.span`
    font-size: 1.6rem;
    margin: 1rem 0 0 0;
`;

const Question = Styled.h5`
    font-size: 4rem;
    line-height: 5rem;
    font-weight: 600;
    color: inherit;
    letter-spacing: -0.3px;
    margin: 10rem 0 6rem 0;
`;


export default ({
    question, options, gridify
  }) => (
    <Layout>
        <Meta
            title="The Best Design Software"
            description="Find out which design tool fits your needs"
            url="/app"
        />
        <Container purple>
            <Content>
                <Screen>
                    <Question>{question}</Question>

                    <Options>
                        {options.map((option) => {
                            return (
                                <NavButtonLink href={option.link}>
                                    <ButtonCard gridify={gridify ? true : false}>
                                        <Label>{option.label}</Label>
                                        <Caption>{option.caption}</Caption>
                                    </ButtonCard>
                                </NavButtonLink>
                            )
                        })}
                    </Options>
            
                </Screen>
            </Content>
        </Container>
    </Layout>
  )
  