import * as React from "react"

import PickerScreen from '../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What level of fidelity?"
    options={[
      {
        label: "High-fidelity",
        caption: "(realistic mockup, detailed design)",
        link: "/app/digital-product-design/hi-fi/"
      },
      {
        label: "Low-fidelity",
        caption: "(paper-style interface, wireframes)",
        link: "/app/digital-product-design/lo-fi/"
      }
  ]}/>
)

export default Picker;


